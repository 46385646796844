.feed {
    width: 100%;
    background: linear-gradient(180deg,white, #bfb4ca);
    /* min-height: 100vh; */
    min-height: 80vh;
}
.feed-container {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    align-items: center;
    margin-top: 80px;
    margin-left:auto;
    margin-right: auto;
    margin-bottom: 0px;
}

.post-feed {
    display: flex;
    flex-direction: column-reverse;
}

.post {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 5px;
}

.post-text {
    width:100vh;
    /* display:flex; */
    text-align: center;
    /* overflow: hidden; */
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
    word-wrap: break-word;
}

.signin {
    display:flex;
    justify-content: center;
    align-items: center;
    /* margin-top:100px; */
    /* padding-bottom:100px; */
    width: 100%;
    background: linear-gradient(180deg,white, #bfb4ca);
    flex-direction: column;
    border-width: 5px;
    border-color: #2a1144;
    min-height: 90vh;
}
.signinbox {
    display:flex;
    justify-content: center;
    align-items: center;
    align-content: space-evenly;
    flex-direction: column;
    background: linear-gradient(180deg,#bfb4ca, white);
    /* height: 200px; */
    width: 400px;;
    border-radius: 7px;
    text-align: center;
    border-width: 4px;
    border-color: blueviolet;
    padding-top: 30px;
    padding-bottom: 40px;
    line-height: 0;
    color: #2a1144;
}
.center {
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */

}