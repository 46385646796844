
.floating-button {
    position: fixed;
    bottom: 70px;
    right: 100px;
    background-color: #8466a2;
    color: white;
    width: 110px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 21px;
    border-radius:30px; 
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .floating-button:hover {
    background-color: #9b84b5;
  }