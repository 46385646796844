nav {
    position: fixed;
    top:0;
    width:100vw;
    backdrop-filter: blur(10px);
}
.navbar {
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin:15px;
    margin-left:25px;
    margin-right:25px;
}

button {
    padding:10px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 7px; 
    border-radius:17px; 
    border-width: 1px;
    border-style: solid;
}
.primaryButton {
    background-color:none;
    border-color: #7d58a2;
}
.secondaryButton {
    background-color:#7d58a2;
    border-color: #7d58a2;
    color:white;
}
button:hover {
    background-color:#8e74ac;
}