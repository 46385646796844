footer {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
    color: #5a5a5a;
    background-color: #bfb4ca;
    /* padding: 11px; */
    line-height: 0%;
    /* position: fixed;
    bottom: 0; */
    width: 100%;
    margin-top: auto;
}
a {
    font-size: 20px;
    text-decoration: none;
    color: rgb(90, 90, 90);
}
@media only screen and (max-width: 600px){
    .footer {
        /* display: flex; */
        flex-direction: column;
    }
}