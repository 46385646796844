.field{
    width: 100%;
    background: linear-gradient(180deg,white, #bfb4ca);
}

.posting-container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    align-items: center;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    min-height: 79vh;
  }

  .post-input {
    padding: 10px;
    width: 60%;
    margin-bottom: 10px;
    border-radius: 4px 4px 4px 4px;
  }
  .post-textarea {
    width: 100%;
    height: 200px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px 4px 4px 4px;
  }
  
  .post-select {
    width: 50%;
    padding: 10px;
    margin-bottom: 10px;
    list-style-type: none;
    background-color: #fff;
    border: 1px solid #6a6969;
    border-radius: 4px 4px 4px 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  .post-button {
    background-color: none;
    border-color: #8466a2;
    border-radius:17px; 
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    margin-bottom: 145px;
  }
  
  .post-button:hover {
    background-color: #8466a2;
  }
  