.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
    background: linear-gradient(180deg,white, #bfb4ca);
    width: 100%; 
}

.titlecontainer {
    text-align: center;
    justify-content: center;
    margin-bottom: 150px;
}

.secondarycontainer {
    display: inline-flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
    margin-left: 0px;
    text-align:left;
    /* justify-content: flex-start; */
    /* color:white; */
    align-items: center;
    margin-bottom: 100px;
}
.rightbox {
    /* background-color: white; */
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
    margin-left: 0px;
    /* width: 50%; */
    /* align-content: center; */
    justify-content: flex-start;
    align-items: flex-end;
}
.leftbox {
    /* background-color: white; */
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
    margin-left: 0px;
    /* align-items: center; */
    justify-content: flex-start;
    align-items: flex-end;
}

.home-button {
    background-color:#7d58a2;
    color:white;
    /* position: absolute;  */
    width:200px;
    border-radius: 40px;
    padding: 20px; 
    font-size: large; 
    margin-top: 30px;
    margin-bottom:30px;
    /* margin-top: 20px;
    left: 50%;
    transform: translateX(-50%); */
}

.title {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.subtitle {
    font-size: 24px;
    font-weight: bold;
    color: #2a1144;
    /* margin-bottom: 10px; */
    
}

.subtitle2 {
    font-size: 40px;
    font-weight: bold;
    color: #2a1144;
    /* margin-bottom: 10px; */
    
}

.description {
    font-size: 20px;
    /* width: 50%; */
    color: #333;
    /* margin-left: auto;
    margin-right: auto; */
    /* margin-bottom: 20px; */
}
@media only screen and (max-width: 600px){
    .secondarycontainer {
        flex-direction: column;
    }
    
}